export class HourlyBilling {
  itemName: string;
  itemHourlyRate: number;
  itemHours: number;
  constructor() {
    this.itemName = 'Untitled';
    this.itemHourlyRate = 10;
    this.itemHours = 1;
  }
}
