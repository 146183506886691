export class Business{
  public coreDetails = {
    Street: '',
    'House Number': '',
    City: '',
    'Zip Code': '',
    State: '',
    'Phone Number': '',
    Email: ''
  };
  constructor() {

  }

}
