import { Component, OnInit } from '@angular/core';
import { Business } from "../services/business";

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  constructor(public bd: Business) { }

  ngOnInit() {
  }

  /**
   * getBusinessDetails
   */
  public getBusinessDetails() {
    return this.bd.coreDetails;
  }

  /**
   * getUserAgreement
   */
  public getUserAgreement() {

  }

  /**
   * getPrivacyPolicy
   */
  public getPrivacyPolicy() {

  }
  /**
   * getUserInformationDisposalPolicy
   */
  public getUserInformationDisposalPolicy() {

  }

}
