import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  public windows = {
    supported: true,
    link: {
      support: '/assets/PlatformIcons/Programs-Windows-icon-yes.png',
      no_support: '/assets/PlatformIcons/Programs-Windows-icon.png'
    }
  };
  public mac = {
    supported: true,
    link: {
      support: '/assets/PlatformIcons/Folder-mac-alt-icon-yes.png',
      no_support: '/assets/PlatformIcons/Folder-mac-alt-icon.png'
    }
  };
  public android = {
    supported: false,
    link: {
      support: '/assets/PlatformIcons/android-icon-yes.png',
      no_support: '/assets/PlatformIcons/android-icon.png'
    }
  };
  public ios = {
    supported: false,
    link: {
      support: '/assets/PlatformIcons/apple-icon-yes.png',
      no_support: '/assets/PlatformIcons/apple-icon.png'
    }
  };
  public linux = {
    supported: false,
    link: {
      support: '/assets/PlatformIcons/folder-teal-linux-icon-yes.png',
      no_support: '/assets/PlatformIcons/folder-teal-linux-icon.png'
    }
  };
  constructor() {
  }

  public supported(platformSupportQuery) {
    let result;
    switch (platformSupportQuery) {
      case 'windows':
        result = this.windows.supported ? this.windows.link.support : this.windows.link.no_support;
        break;
      case 'mac':
        result = this.mac.supported ? this.mac.link.support : this.mac.link.no_support;
        break;
      case 'android':
        result = this.android.supported ? this.android.link.support : this.android.link.no_support;
        break;
      case 'ios':
        result = this.ios.supported ? this.ios.link.support : this.ios.link.no_support;
        break;
      case 'linux':
        result = this.linux.supported ? this.linux.link.support : this.linux.link.no_support;
        break;
      default:
        result = '';
        break;
    }
    return result;
  }

  ngOnInit() {
  }
}
