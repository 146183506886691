import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { UrlSanitization } from "./url-sanitization.pipe";
import { InvoiceNameExtract } from "./invoice-name-extraction.pipe";

@NgModule({
  declarations: [UrlSanitization, InvoiceNameExtract],
  imports: [CommonModule],
  exports: [UrlSanitization, InvoiceNameExtract]
})

export class CustomPipe {

}
