import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth.guard';

// Components

import { DashboardComponent } from './dashboard/dashboard.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BillingPluginComponent } from './billing-plugin/billing-plugin.component';
import { DocumentationPluginComponent } from './documentation-plugin/documentation-plugin.component';
import { AngularFireFunctions } from '@angular/fire/functions';
import { InstantMessengerComponent } from './instant-messenger/instant-messenger.component';
import { UserAndPrivacyComponent } from "./user-and-privacy/user-and-privacy.component";
import { RoadmapComponent } from './roadmap/roadmap.component';
import { InvoiceComponent } from "./invoice/invoice.component";


const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'about', component: AboutUsComponent},
  { path: 'im', component: InstantMessengerComponent},
  { path: 'doc', component: DocumentationPluginComponent},
  { path: 'privacy-agreement', component: UserAndPrivacyComponent},
  { path: 'roadmap', component: RoadmapComponent},
  { path: 'invoice/:fk/:name/:id', component: InvoiceComponent},
  { path: 'bill', component: BillingPluginComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
