import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { HttpClientModule, HttpClient, HttpHeaders, HttpEventType} from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AngularEditorComponent, AngularEditorConfig } from '@kolkov/angular-editor';
import { AwsStorageService } from '../services/aws-storage.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-documentation-plugin',
  templateUrl: './documentation-plugin.component.html',
  styleUrls: ['./documentation-plugin.component.css']
})
export class DocumentationPluginComponent implements OnInit {
  public htmlContent = '';
  public htmlTitle = 'Untitled';
  private currentlyEditable = false;
  public openADoc = false;
  public hasSavedDocuments = false;
  public headers = new HttpHeaders({
    Authorization: this.auth.user.token
  });
  private testheaders = new HttpHeaders({
    Authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7fSwiaWF0IjoxNTg0MTkxMTA0fQ.qzg50wECW0tpgXHba-VSYGIBz5Lm1K-owsLOBVZ-Z2Q'
  });
  public tts = window.speechSynthesis;
  public voiceArray = [];
  public currentVoice;
  // PDF stuff
  private currentPage = 1;
  private totalPages = 0;
  private pageRenderInProgress = 1;
  private userFiles;

  constructor(private httpcl: HttpClient, public aws: AwsStorageService, private auth: AuthService) {
  }

  ngOnInit() {
    console.log(this.aws.cloudDocuments);
    if (this.aws.cloudDocuments.length > 0) {
      this.hasSavedDocuments = true;
    }
    setTimeout(() => {
      this.voiceArray = this.tts.getVoices();
    }, 1000);

  }


  public onlyUnique() {
    let cloudDocs = this.aws.cloudDocuments;
    return cloudDocs.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
  }

  public getDoc(file) {
    let jsonObj = {
      uid: this.auth.user.uid,
      filename: file
    };
    this.httpcl.get( this.aws.file_api + '/api/getdoc', { headers: this.headers, params: jsonObj})
    .subscribe((data) => {
      this.htmlTitle = jsonObj.filename;
      this.htmlContent = data['content'];
    });
  }

  public openDoc() {
    this.openADoc = true;
  }

  public saveToCloud() {
    let jsonObj = {
      uid: this.auth.user.uid,
      filename: this.htmlTitle,
      content: this.htmlContent,
      date: Date.now()
    };
    this.httpcl.post( this.aws.file_api + '/api/doc', { headers: this.headers, params: jsonObj})
    .subscribe((data) => {

    });
  }
  /**
   * saveToDesktop
   */
  public saveToDesktop() {
    let jsonObj = {
      filename: this.htmlTitle,
      content: this.htmlContent,
      date: Date.now()
    };
  }
  // Text to Speech
  private getSelectedText() {
    let text = window.getSelection().toString();
    if (text === undefined || text === '') {
      return this.htmlContent;
    }
    return text;
  }
  public speakThis() {
    let utter = new SpeechSynthesisUtterance(this.getSelectedText());
    utter.voice = this.currentVoice === undefined ? this.voiceArray[0] : this.currentVoice;
    this.tts.speak(utter);
  }
  public changeVoice(index){
    this.currentVoice = this.voiceArray[index];
  }

  public uploadDocXImport() {
    document.getElementById('docxImport').click();
  }
  public importDOCX(event) {
    this.userFiles = event.target.files[0];
    const data = new FormData();
    data.append('file', this.userFiles);

    this.httpcl.post( 'http://localhost:3000' + '/api/import/docx/raw', data, { headers: this.headers })
      .subscribe( (response) => {
          this.htmlContent = response['response'].value;
        }
    );
  }
}
