import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgxPrintModule } from "ngx-print";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireDatabaseModule } from "@angular/fire/database";

import { config } from 'rxjs';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { FaqComponent } from './faq/faq.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { DocumentationPluginComponent } from './documentation-plugin/documentation-plugin.component';
import { BillingPluginComponent } from './billing-plugin/billing-plugin.component';
import { ProjectManagementPluginComponent } from './project-management-plugin/project-management-plugin.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Invoice } from './services/invoice';
import { FilesComponent } from './files/files.component';
import { AngularFireFunctions } from '@angular/fire/functions';
import { LegalComponent } from './legal/legal.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AdminComponent } from './admin/admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoadingbarComponent } from './loadingbar/loadingbar.component';
import { InstantMessengerComponent } from './instant-messenger/instant-messenger.component';
import { UserAndPrivacyComponent } from './user-and-privacy/user-and-privacy.component';
import { RoadmapComponent } from './roadmap/roadmap.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { UserinvoicesComponent } from './userinvoices/userinvoices.component';
import { CustomPipe } from './custom-pipes/custome-pipers.module';

export const firebaseConfig = {
  development: {
    apiKey: 'AIzaSyByryILStpU4em1ZIc3Xw8zuzlnfoKKqEQ',
    authDomain: 'designlabtester.firebaseapp.com',
    databaseURL: 'https://designlabtester.firebaseio.com',
    projectId: 'designlabtester',
    storageBucket: 'designlabtester.appspot.com',
    messagingSenderId: '459479429406',
    appId: '1:459479429406:web:942304fd85b3fdd5'
  },
  production: {
    apiKey: 'AIzaSyAwcUSdJhHIQvz7j8jjsg7lgnXK6xT3sF0',
    authDomain: 'de3ignsketch.firebaseapp.com',
    databaseURL: 'https://de3ignsketch.firebaseio.com',
    projectId: 'de3ignsketch',
    storageBucket: 'de3ignsketch.appspot.com',
    messagingSenderId: '440086568482',
    appId: '1:440086568482:web:9e679edca9435d6c02b47b',
    measurementId: 'G-N4V8ZG2NLQ'
  }
};

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    UserProfileComponent,
    LandingpageComponent,
    FaqComponent,
    SubscriptionsComponent,
    DocumentationPluginComponent,
    BillingPluginComponent,
    ProjectManagementPluginComponent,
    AboutUsComponent,
    FilesComponent,
    LegalComponent,
    PrivacyComponent,
    AdminComponent,
    LoadingbarComponent,
    InstantMessengerComponent,
    UserAndPrivacyComponent,
    RoadmapComponent,
    InvoiceComponent,
    UserinvoicesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(firebaseConfig.production),
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    FormsModule,
    AngularEditorModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxPrintModule,
    CustomPipe
  ],
  providers: [Invoice, AngularFireFunctions],
  bootstrap: [AppComponent]
})
export class AppModule { }
