import { Pipe, PipeTransform} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'invnameextraction' })

export class InvoiceNameExtract implements PipeTransform {

  constructor(private sanitizer: DomSanitizer){

  }

  transform(url: string) {
    let temp = url.split
    let result = temp[temp.length - 1];
    return result;
  }
}
