import { Component, OnInit, AfterViewInit } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from "@angular/common/http";
import { AuthService } from '../services/auth.service';
import { AwsStorageService } from '../services/aws-storage.service';
import { Observable } from 'rxjs';
import { MobileService } from '../services/mobile.service';
import { SubscriptionsService } from '../services/subscriptions.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.css']
})
export class FilesComponent implements OnInit, AfterViewInit {
  private tokenized;
  public pageNumber = 1;
  public uploading = false;
  public files$: Observable<{fileData: object}>;
  public dataLoaded = false;
  public fileDataContainer = {};
  public files = [];
  public userFiles = [];
  public userACPNNFiles = [];
  public headers = new HttpHeaders({
    Authorization: this.auth.user.token
  });
  private approvedExt = ['jpg', 'png', 'd2l', 'doc', 'txt', 'inv', 'pdf', 'tiff', 'acpnn', '7z'];
  public fileIconObject = {
    jpg: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/jpgicon.png',
    png: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/pngicon.png',
    d2l: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/d2licon.png',
    doc: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/docicon.png',
    txt: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/docicon.png',
    inv: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/invicon.png',
    pdf: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/pdficon.png',
    tiff: 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/tifficon.png',
    acpnn: '../assets/FileIcons/ACPNN_OR_ZIPpng.png',
    '7z': '../assets/FileIcons/ACPNN_OR_ZIPpng.png'
  };
  constructor(
    private httpcl: HttpClient,
    private auth: AuthService,
    private aws: AwsStorageService,
    public mobileserv: MobileService,
    public subscription: SubscriptionsService) { }

  ngOnInit() {
    this.mobileserv.currentlyLoaded = 1;
    this.tokenize(this.auth.user.uid).subscribe(
      (res) => {},
      (error) => {},
      () => {
        this.getFiles();
      }
    );
  }

  ngAfterViewInit() {
  }

  public viewOption(): boolean {
    return this.mobileserv.viewOption;
  }


  private tokenize(uid) {
    let params = {
      uid: this.auth.user.uid
    };
    return this.httpcl.post(this.aws.file_api + '/api/happy', params);
  }
  // getFiles is responsible for getting the list of files the user has
  public getFiles() {

    let data = { 'uid': `${this.auth.getUser().uid}`};
    this.files$ = this.httpcl.get<{fileData: object}>( this.aws.file_api + '/api/files', { headers: this.headers, params: data } );
    this.files$.subscribe( value => {
        this.dataLoaded = true;
        this.files = value['Content'];
        this.fileDataContainer = {
          'TotalSize': value['TotalSize']
        };

        // let subParams = {
        //   uid: this.auth.user.uid,
        //   subID: this.auth.user.subscriptionID,
        //   totalSpace: this.fileDataContainer['TotalSize']
        // }
        // this.httpcl.post( this.aws.file_api + '/sub/check', subParams, {headers: this.headers} ).subscribe( (data) => {
        //     console.log(data);
        // });
    });
  }


  /**
   * getSubscriptionDate
   */
  public getSubscriptionDate() {
    let subDate = new Date(this.auth.user.subscriptionLevel);
    let displayDate = subDate.toLocaleString().split(',')[0];
    return displayDate;
  }
  public stillSubbed() {
    let subbed = this.subscription.subbedData.result;
    let reason = this.subscription.subbedData.reason;
    console.log(reason);
    console.log(subbed)
    return subbed;
  }
  /**
   * getExtension
   */
  public getExtension(fileName: string) {
    let extension, extensionFilter;
    try{
      extension = fileName.split('.');
      extensionFilter = extension[extension.length - 1].toLowerCase();
      if (extensionFilter === 'doc'){
        this.aws.cloudDocuments.push(fileName.split('.')[0]);
      }
      if (extensionFilter === 'inv'){
        this.aws.invoiceDocuments.push(fileName);
      }
      if ( this.fileIconObject[extensionFilter] === undefined) {
        return '../assets/FileIcons/_0004_Unknown.png';
      } else {
        return this.fileIconObject[extensionFilter];
      }
    } catch (error) {
      return 'https://s3-us-west-2.amazonaws.com/s.cdpn.io/312369/docicon.png';
    }
  }
  // Responsible for handling user uploads
  /**
   * onUserFilesSelected
   */
  public onUserFilesSelected(event) {

    let subParams = {
      uid: this.auth.user.uid,
      subID: this.auth.user.subscriptionID,
      totalSpace: this.fileDataContainer['TotalSize']
    };

    this.userFiles = event.target.files;
    const data = new FormData();
    data.append('uid', this.auth.getUser().uid);
    for (let i = 0; i < this.userFiles.length; i++) {
      data.append("files", this.userFiles[i]);
    }
    this.httpcl.post( this.aws.file_api + '/api/upload', data, {
      params: subParams,
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.aws.userFileUploadProgress = event.loaded / event.total * 100;
          console.log(this.aws.userFileUploadProgress);
          if (event.loaded === event.total) {
            setTimeout( () => {
              this.getFiles();
            }, 5000);
          }
        }
      });
  }
  /**
   * onUserACPNNSelected
   */
  public onUserACPNNSelected(event) {
    this.userACPNNFiles = event.target.files;

    let subParams = {
      uid: this.auth.user.uid
    };

    this.userFiles = event.target.files;
    const data = new FormData();
    data.append('uid', this.auth.getUser().uid);
    for (let i = 0; i < this.userFiles.length; i++) {
      data.append("files", this.userFiles[i]);
    }
    this.httpcl.post( this.aws.file_api + '/api/acpnn/request', data, {
      params: subParams,
      headers: this.headers,
      reportProgress: true,
      observe: 'events'
    })
      .subscribe(event => {
        console.log(event);
      });
  }

  // Responsible for downloading the file that has been clicked
  public downloadFile(fileName){
    let data = {
      uid: this.auth.getUser().uid,
      fileName: fileName
     };
    this.httpcl.get( this.aws.file_api + '/api/file', { headers: this.headers, params: data })
      .subscribe((data) => {
        window.open(data['getUrl'], "_blank");
      });
  }

  // Responsible for deleting a file from the system
  public deleteFile(file) {
    let data = {
      uid: this.auth.user.uid,
      fileName: file
    };
    this.httpcl.post( this.aws.file_api + '/api/del', {params: data}, {headers: this.headers}).subscribe( (data) => {
      setTimeout( () => {
        this.getFiles();
      }, 1000);
    });
  }
}
