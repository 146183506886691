import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './user.model';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireDatabase } from "@angular/fire/database";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { AwsStorageService } from '../services/aws-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User>;
  public loginError = false;
  public loggedout = true;
  public user: User = {
    email: '',
    displayName: '',
    uid: '',
    subscriptionLevel: '',
    subscriptionID: '',
    admin: false,
    token: ''
  };

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private aws: AwsStorageService,
    private afdb: AngularFireDatabase,
    private router: Router,
    private httpcl: HttpClient
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          this.loginError = false;
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          this.loginError = true;
          return of(null);
        }
      })
    );
    this.user$.subscribe( value => {
      this.user = value;
    });
   }
   getUser() {
     return this.user;
   }

   modifySubscription() {
     let month = new Date();
     month.setMonth(month.getMonth() + 1);
     this.user.subscriptionLevel = month.toLocaleString();
     this.updateUserData(this.user, '');
   }
   public forceUpdate() {

   }

   async googleSignIn() {
     const provider = new auth.GoogleAuthProvider();
     const credential = await this.afAuth.auth.signInWithPopup(provider);

     this.router.navigate(['/dashboard']);
     this.loggedout = false;

     this.updateUserData(credential.user, '');
   }
   private updateUserData(user, dName) {
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
    let randomizedForeignKey = Math.random().toString(16).substring(7);

    console.log(user);
    const data = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName === null || user.displayName === undefined ? dName : user.displayName,
      subscriptionID: user.subscriptionID || 1,
      subscriptionLevel: user.subscriptionLevel === undefined ? (new Date()).toLocaleString() : user.subscriptionLevel,
      admin: user.admin || false,
      fk: user.fk || randomizedForeignKey
    };

    userRef.set(data, {merge: true} );
   }

   async googleSignOut() {
     await this.afAuth.auth.signOut();
     this.loggedout = true;
     this.router.navigate(['/']);
   }

   async registerEmail(email: string, password: string, dName: string){
      var result = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);
      return this.updateUserData(result.user, dName);
      // this.sendEmailVerification();
   }
   async emailSignIn(email: string, password: string) {
      let result = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
   }
   async forgotPassword(email: string){
    return await this.afAuth.auth.sendPasswordResetEmail(email);
   }
   isLoggedIn() {
    return this.afAuth.authState.pipe(first()).toPromise();
   }

   getUserIssuedInvoices(){
     return this.afdb.object(`invoices/${this.user.fk}`);
   }

   getUserIssuedInvoice(fk, clientName, invNum){
    // const invRef: AngularFirestoreDocument = this.afs.doc(`invoices/${invNum}`);
    return this.afdb.object(`invoices/${fk}/${clientName}/${invNum}`).valueChanges();
   }

   publishAsInvoice(){ //clientName, inv
     let clientName = 'Charles';
     let inv = {"fileName":"A Sample","greeting":"Hello","client":{"honorific":"Mr.","lastName":"Example","street":"Street Example","house":1104,"ext":"Apt.","extNum":44,"city":"City Ex","state":"NA","zip":11111},"invoicer":{"firstName":"Bob","lastName":"Marley"},"hourlyItems":[{"itemName":"Untitled","itemHourlyRate":10,"itemHours":1},{"itemName":"Untitled","itemHourlyRate":10,"itemHours":1}],"signOff":"Goodbye","invoiceNumber":1};
     const invoiceLinkName = Math.random().toString(16).substring(7);

     const itemRef = this.afdb.object(`invoices/${this.user.fk}/${clientName}/${ invoiceLinkName }/`);
     itemRef.update(inv);
   }

   addClientForPublishedInvoices(clientName){
    const clientRef = this.afdb.object(`invoices/${this.user.fk}/${clientName}`);
    clientRef.update({name: clientName});
   }

   invoiceWasOpened(fk, clientName, invNum){
     const itemRef = this.afdb.object(`invoices/${fk}/${clientName}/${invNum}`);
     itemRef.update({read: true});
   }
}
