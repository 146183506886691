import { Injectable, Input, HostListener } from '@angular/core';
import { AuthService } from "./auth.service";
import { AwsStorageService } from './aws-storage.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  public headers = new HttpHeaders({
    Authorization: this.auth.user.token
  });
  public subbedData = { result: false, reason: 'default' };
  constructor(
    private auth: AuthService,
    private aws: AwsStorageService,
    private httpcl: HttpClient
  ) {
    this.stillSubbed();
    console.log(this.subbedData);
  }

  public stillSubbed() {
    let params = {
      uid : this.auth.user.uid
    }
    this.httpcl.get(this.aws.file_api + '/subs/issubscribed', { headers: this.headers, params}).subscribe( (data) => {
      this.subbedData.result = data['result'];
      this.subbedData.reason = data['reason'];
    });
  }
}
