import { Component, OnInit } from '@angular/core';
import { AwsStorageService } from '../services/aws-storage.service';

@Component({
  selector: 'app-loadingbar',
  templateUrl: './loadingbar.component.html',
  styleUrls: ['./loadingbar.component.css']
})
export class LoadingbarComponent implements OnInit {

  constructor( public aws: AwsStorageService) { }

  ngOnInit() {
  }

  public getProgress() {
  }
}
