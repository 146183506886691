import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-roadmap',
  templateUrl: './roadmap.component.html',
  styleUrls: ['./roadmap.component.css']
})
export class RoadmapComponent implements OnInit {
  public roadmapCurrentImg = '../assets/Backgrounds/DesignSketchRoadmap.jpg';
  public roadmapCurrentImgHeight = 1080;
  public roadmapCurrentImgWidth = 1920;
  public roadmapHeightDisplay = this.roadmapCurrentImgHeight * 0.25;
  public roadmapWidthDisplay = this.roadmapCurrentImgWidth * 0.25;

  constructor() { }

  ngOnInit() {
  }

  public adjustRoadmapSize(perc){
    this.roadmapHeightDisplay = this.roadmapCurrentImgHeight * (perc/100);
    this.roadmapWidthDisplay = this.roadmapCurrentImgWidth * (perc/100);
  }
}
