import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthService } from "../services/auth.service";
import { Observable } from "rxjs";
import { AwsStorageService } from '../services/aws-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  private headers = new HttpHeaders({
    Authorization: this.auth.user.token
  });
  public currentlyPubbedInvoices = [];

  constructor(
    private auth: AuthService,
    private aws: AwsStorageService,
    private httpcl: HttpClient
    ) {
      this.getCurrentlyPublishedInvoices();
  }

  ngOnInit() {
  }

  private getCurrentlyPublishedInvoices() {
    const params = {};
    this.httpcl.get(this.aws.file_api + `/api/inv/${this.auth.user.uid}/invoices`, { headers: this.headers, params}).subscribe( (data) => {
      for (const item in data) {
        if (item) {
          this.currentlyPubbedInvoices.push( item );
        }
      }
    });
  }

  public displayPublishedInvoices() {
    return this.aws.currentlyPublishedInvoices;
  }

  public produceInvoiceUrl(name){
    window.open(this.aws.file_api + '/api/inv/issue/' + name, '_blank');
  }
}
