import { HourlyBilling } from './hourly-billing';

export class Invoice {
  public fileName: string;
  public greeting: string;
  public client: {
    honorific: string,
    lastName: string,
    street: string,
    house: number,
    ext: string,
    extNum: number,
    city: string,
    state: string,
    zip: number
  };
  public invoicer: {
    firstName: string,
    lastName: string,
  };
  public hourlyItems: object[];
  public signOff: string;
  public invoiceNumber: number;

  constructor() {
    this.fileName = 'Untitled';
    this.greeting = 'Hello';
    this.client = {
      honorific: 'Mr.',
      lastName: 'Example',
      street: 'Street Example',
      house: 1104,
      ext: 'Apt.',
      extNum: 44,
      city: 'City Ex',
      state: 'NA',
      zip: 11111
    };
    this.invoicer = {
      firstName: 'Bob',
      lastName: 'Marley',
    };
    this.hourlyItems = [new HourlyBilling()];
    this.signOff = 'Goodbye';
    this.invoiceNumber = 1;
  }
}
