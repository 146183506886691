
export const defaultGreetingVar = [
  'Hello', 'Greetings',
  'I Hope you\'re having a good day',
  'Good morning', 'Good afternoon',
  'Good evening', 'It’s nice to meet you',
  'It’s a pleasure to meet you', 'Hi',
  'Morning', 'How are things', 'What’s new',
  'It’s good to see you', 'G’day',
  'Howdy', 'Hey', 'Hey there',
  'What’s up', 'How’s it going', 'What’s happening',
  'What’s happenin’', 'Yo'
];
export const defaultStreetExt = ['', 'Apt.', 'Unit'];
export const defaultExitsVar = [
  'Have A Nice Day', 'Talk to You Soon',
  'Sincerely', 'Namaste',
  'Goodbye', 'Farewell', 'Have a Good Day',
  'Take Care', 'Bye', 'Bye Bye', 'See You Later',
  'Talk to you later', 'Have a good one', 'So long',
  'All right then', 'Catch you later', 'Peace', 'Peace out',
  'I\'m out', 'Smell you later', 'Adios', 'Ciao', 'Au revoir', 'Sayonara'];
export const defaultHonorifics = [
  'Mr.', 'Mrs.', 'Miss', 'Ms.',
  'Dr.', 'Professor', 'The Rt Revd Dr',
  'The Most Revd', 'The Rt Rev.d', 'The Rev.d Canon', 'The Rev.d', 'The Rt. Rev.d Professor', 'The Ven.', 'The Most Rev.d Dr.',
  'Rabbi', 'Canon', 'Dame', 'Chief', 'Sister', 'Reverend', 'Major', 'Sir', 'Rt. Hon. Lord', 'Rt. Hon.', 'The Lord ',
  'Viscount', 'Viscountess', 'Baroness', 'Captain', 'Master', 'Lady', 'M.P.'];

