import { Component, OnInit, Injectable } from '@angular/core';
import { defaultExitsVar, defaultGreetingVar, defaultHonorifics, defaultStreetExt } from "./billing-defaults.module";
import { Invoice } from '../services/invoice';
import { HourlyBilling } from '../services/hourly-billing';
import { AwsStorageService } from '../services/aws-storage.service';
import { AuthService } from '../services/auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: Invoice
})
@Component({
  selector: 'app-billing-plugin',
  templateUrl: './billing-plugin.component.html',
  styleUrls: ['./billing-plugin.component.css']
})
export class BillingPluginComponent implements OnInit {
  private headers = new HttpHeaders({
    Authorization: this.auth.user.token
  });
  public defaultGreetingVar = defaultGreetingVar;
  public defaultStreetExt = defaultStreetExt;
  public defaultExitsVar = defaultExitsVar;
  public defaultHonorifics = defaultHonorifics;
  public expandHeaders = false;
  public expandIntroduction = false;
  public expandBody = false;
  public expandSignature = false;
  public expandFooter = false;
  public gIN = false;

  public itemName = '';
  public itemRate = 0;
  public itemHourlyRate = 0;
  private currentlySetItem = 0;

  public currentlyPubbedInvoices = {};

  constructor(
    public invoice: Invoice = new Invoice(),
    private httpcl: HttpClient,
    private aws: AwsStorageService,
    private auth: AuthService
    ) {
    this.addItem();
  }

  ngOnInit() {
  }

  public expandHead() {
    this.expandHeaders = this.expandHeaders === true ? false : true;
  }
  public expandIntro() {
    this.expandIntroduction = this.expandIntroduction === true ? false : true;
  }
  public expandBod() {
    this.expandBody = this.expandBody === true ? false : true;
  }
  public expandSig() {
    this.expandSignature = this.expandSignature === true ? false : true;
  }
  public expandFoot() {
    this.expandFooter = this.expandFooter === true ? false : true;
  }
  public getInvNames() {
    this.gIN = this.gIN === true ? false : true;
  }

  /**
   * addItem adds and item to Hourly Items
   */
  public addItem() {
    let item = new HourlyBilling();
    this.invoice.hourlyItems.push(item);
  }

  /**
   * modifyCurrentItem
   */
  public modifyCurrentItem() {
    const item = this.currentItem(this.currentlySetItem);
    item['itemName'] = this.itemName;
    item['itemHourlyRate'] = this.itemHourlyRate;
    item['itemHours'] = this.itemRate;
  }

  /**
   * getTotal
   */
  public getTotal(): number {
    let returnAmount = 0;
    for (let i = 0; i < this.invoice.hourlyItems.length; i++) {
      let item = this.invoice.hourlyItems[i];
      returnAmount += item['itemHours'] * item['itemHourlyRate']
    }
    return returnAmount;
  }

  public currentItem(index: number): object {
    return this.invoice.hourlyItems[index];
  }

  public currentItemSet(event: string) {
    this.currentlySetItem = parseInt(event);
  }


  /**
   * removeItem removes a specific item from Hourly Items
   */
  public removeItem() {
    this.invoice.hourlyItems.splice(this.currentlySetItem, 1);
  }

  public get getInvoiceNames(): string[] {
    return this.aws.invoiceDocuments.filter( (value, index, self) => {
      return self.indexOf(value) === index;
    });
  }


  public saveToCloud() {
    let params = {
      uid: this.auth.user.uid,
      content: this.invoice
    }
    this.httpcl.post( this.aws.file_api + '/api/inv', params, {headers: this.headers}).subscribe( (data) => {

    });
  }

  public printInv() {

  }

  public loadInv(file) {
    let params = {
      uid: this.auth.user.uid,
      filename: file
    }
    this.httpcl.get(this.aws.file_api + '/api/getinv', { headers: this.headers, params } ).subscribe( (data) => {
      this.invoice = data['content'];
    });
  }

  public publishInvoice() {
    const params = {
      uid: this.auth.user.uid,
      data: JSON.stringify(this.invoice)
    };
    this.httpcl.post(this.aws.file_api + '/api/inv/issue', params, { headers: this.headers}).subscribe( (data) => {
      return data;
    });
  }

}
