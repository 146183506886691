import { Injectable, HostListener } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  // contain screen dimensions
  public currentlyLoaded = 0;
  private screen = {
    screenHeight: false,
    screenWidth: false
  };
  private phone = {
    height: 823,
    width: 411
  };
  private mobile = false;
  public viewOption = false;

  constructor(public bpo: BreakpointObserver) {
    this.screen.screenWidth = bpo.isMatched('(max-width: ' + this.phone.width + 'px)');
    this.screen.screenHeight = bpo.isMatched('(max-height:' + this.phone.height + 'px)');
    this.mobile = this.screen.screenWidth || this.screen.screenHeight ? true : false;
  }
  public getScreenSize(event?) {
    return this.mobile;
  }

  public componentPage(page: number): boolean {
    return page === this.currentlyLoaded ? true : false;
  }

}
