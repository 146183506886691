import {
  Component, OnInit, Input,
  ElementRef, AfterViewInit, ViewChildren, Renderer2, QueryList } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { AngularFireFunctions } from '@angular/fire/functions';
import { SubscriptionsService } from '../services/subscriptions.service';
import { Subscription } from '../services/subscription.model';
import { MobileService } from '../services/mobile.service';
import { AwsStorageService } from '../services/aws-storage.service';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})

export class SubscriptionsComponent {
  public pageNumber = 2;
  public subAddress = '';
  constructor(
    private auth: AuthService,
    private functions: AngularFireFunctions,
    public subserv: SubscriptionsService,
    public mobilserv: MobileService,
    private aws: AwsStorageService) {
      this.getPayPalLink();
  }


  private getPayPalLink() {
    let root = '/subs/subscriptions/' + this.auth.user.uid;
    let address = this.aws.file_api + root;
    this.subAddress = address;
  }
}
