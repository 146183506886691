import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { SubscriptionsService } from '../services/subscriptions.service';
import { angularEditorConfig } from '@kolkov/angular-editor/lib/config';
import { HttpClient } from '@angular/common/http';
import { AwsStorageService } from "../services/aws-storage.service";
import { MobileService } from '../services/mobile.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent{
  constructor(
    public auth: AuthService,
    public router: Router,
    public ss: SubscriptionsService,
    private httpcl: HttpClient,
    private aws: AwsStorageService,
    public mobileserv: MobileService,
    public subscription: SubscriptionsService) {
  }


  public filesDashboard = true;
  public invoiceDashboard = false;

  public fileMenuOption = false;
  public settingsMenuOption = false;
  public qANDaMenuOption = false;
  public subscribeOption = false;
  public openMenu = false;

  public newInvoiceSwitch = false;
  public newDocSwitch = false;

  public isAdmin = false;

  public viewOption() {
    this.mobileserv.viewOption = this.mobileserv.viewOption ? false : true;
  }

  public dashMenu(option: string) {
    switch (option) {
      case 'file':
        this.fileMenuOption = this.fileMenuOption === true ? false : true;
        this.settingsMenuOption = false;
        this.qANDaMenuOption = false;
        break;
      case 'settings':
        this.settingsMenuOption = this.settingsMenuOption === true ? false : true;
        this.fileMenuOption = false;
        this.qANDaMenuOption = false;
        break;
      case 'qANDa':
        this.qANDaMenuOption = this.qANDaMenuOption === true ? false : true;
        this.settingsMenuOption = false;
        this.fileMenuOption = false;
        break;
      default:
        break;
    }
    this.aws.currentlyPublishedInvoices = false;
    this.interactiveDash();
  }

  /**
   * interactiveDash
   */
  public interactiveDash() {
    if (this.fileMenuOption || this.qANDaMenuOption || this.settingsMenuOption) {
      this.openMenu = true;
    } else {
      this.openMenu = false;
    }
  }

  /**
   * uploadFiles activates the file field in files view
   */
  public uploadFiles() {
    document.getElementById('onUserFilesSelected').click();
  }
  /**
   * uploadACPNN activates the acpnn file field in files view
   */
  public uploadACPNN() {
    let r = confirm('By clicking Ok, you confirm you want to send an ACPNN Request.\n' +
      'The ACPNN is a manual process and will take some time. Additionally, since the ACPNN is new, we are striving to make it better but you may not always get a 3D model back as it may fail.')
    if ( r ) {
      document.getElementById('onUserACPNNSelected').click();
    } else {

    }
  }

  /**
   * newDoc allows for the creation of new documents via the document plugin
   */
  public newDoc() {
    this.newDocSwitch = this.newDocSwitch ? false : true;
    this.newInvoiceSwitch = false;
    this.filesDashboard = false;
  }
  /**
   * newInv allows for the creation of new invoices via the invoice plugin
   */
  public newInv() {
    this.newInvoiceSwitch = true;
    this.aws.currentlyPublishedInvoices = false;
  }
  /**
   * cancelInv
   */
  public cancelInv() {
    this.newInvoiceSwitch = false;
    this.aws.currentlyPublishedInvoices = true;
  }

  public displayPublishedInvoices () {
    return this.aws.currentlyPublishedInvoices;
  }
  /**
   * filesDashboardRender is responsible for rendering out user files via the files component
   */
  public filesDashboardRender() {
    this.filesDashboard = true;

    this.openMenu = false;
    this.fileMenuOption = false;
    this.invoiceDashboard = false;
    this.subscribeOption = false;

    this.newDocSwitch = false;
    this.newInvoiceSwitch = false;
  }

  /**
   * invoiceDashboardRender provides a canvas for Invoices and all methodology for it
   */
  public invoiceDashboardRender() {
    this.invoiceDashboard = true;
    this.aws.currentlyPublishedInvoices = true;

    this.filesDashboard = false;
    this.openMenu = false;
    this.fileMenuOption = false;
    this.subscribeOption = false;

    this.newDocSwitch = false;
    this.newInvoiceSwitch = false;
  }

  // Subscription functionality
  /**
   * subscribe
   */
  public subscribe() {
    try {
      this.subscribeOption = this.subscribeOption ? false : true;
      this.filesDashboard = !(this.subscribeOption);
      this.newDocSwitch = false;
      this.newInvoiceSwitch = false;
    } catch (error) {
      console.log(error, error.stack);
    }
  }
}
