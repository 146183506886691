import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from './services/auth.service';
import { tap, map, take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AwsStorageService } from './services/aws-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router, private httpcl: HttpClient, private aws: AwsStorageService) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.auth.user$.pipe(
      take(1),
      map(user => !!user),
      tap(loggedIn => {
        if (!loggedIn) {
          this.router.navigate(['/']);
          this.auth.loggedout = true;
        } else {
          this.auth.loggedout = false;
        }
      })
    );
  }
}
