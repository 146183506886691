import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class AwsStorageService {

  private FILESAPI = 'https://de3ignsketch-files-api.herokuapp.com';
  private FILESAPI_DEV = 'http://localhost:3000';
  private current_env = 'prod';
  public cloudDocuments = [];
  public invoiceDocuments = [];
  public userFileUploadProgress = 0;
  public currentlyPublishedInvoices = false;

  constructor() { }


  public get file_api(): string {
    if(this.current_env === 'dev'){
      return this.FILESAPI_DEV;
    } else {
      return this.FILESAPI;
    }
  }

}
