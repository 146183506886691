import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { MobileService } from '../services/mobile.service';



@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css']
})
export class LandingpageComponent implements OnInit {
  public emailAvailable = false;
  public showMultiTool = false;
  public showNoComp = false;
  public showAcpnn = false;
  public useEmailLogin = false;
  public hasAgreedToUP = false;

  public failToEmailLogin = false; public failToEmailLoginReason = '';

  public registerEmail = false;
  public displayName = '';
  public regEmail = ''; public regPassword = '';
  public dupEmail = ''; public dupPassword = '';
  public emailMatch = true; public passwordMatch = true;
  public regError = false; regErrorReason = '';

  public passwordReset = false; public passResetEmail = '';

  public emailSimpleEval = /^\S+@\S+$/;
  private passSimpleEval = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/;

  public userEmail = '';
  public userPass = '';
  constructor(public auth: AuthService, public router: Router, public mobile: MobileService) { }

  ngOnInit() {
    this.mobile.getScreenSize();
  }

  public dynamicAdvert(advert) {
    if (advert === '0') {
      this.showMultiTool = this.showMultiTool ? false : true;
    } else if (advert === '1') {
      this.showNoComp = this.showNoComp ? false : true;
    } else if (advert === '2') {
      this.showAcpnn = this.showAcpnn ? false : true;
    } else {
      // it should never reach here
    }
  }

  public emailLogin() {
    if (this.emailSimpleEval.test(this.userEmail) ) {
      // Check if Password is Syntactically
      if ( this.passSimpleEval.test(this.userPass) ) {
      // At this point, it's syntactically valid
      this.auth.emailSignIn(this.userEmail, this.userPass);
      } else {
        this.failToEmailLogin = true;
        this.failToEmailLoginReason = 'Password does not pass basic security check';
      }
    } else {
      this.failToEmailLogin = true;
      this.failToEmailLoginReason = 'Email does not pass basic security check.';
    }
  }

  public dismissFailToEmailLogin() {
    this.failToEmailLogin = false; this.failToEmailLoginReason = '';
  }

  public onUseEmail() {
    this.useEmailLogin = true;
  }

  public onEmailRegister() {
    if(!this.passwordReset){
      this.registerEmail = true;
    }
  }

  public cancelRegisterEmail() {
    this.registerEmail = false;
  }

  public onChangeEmail() {
    this.emailMatch = this.regEmail !== this.dupEmail ? false : true;
  }

  public onChangePass() {
    this.passwordMatch = this.regPassword !== this.dupPassword ? false : true;
  }
  public submitRegistrationForEmail() {
    this.verifyEmailAndPassword(this.regEmail, this.regPassword);
  }

  public onForgotPassword() {
    if(!this.registerEmail){
      this.passwordReset = true;
    }
  }

  public cancelPasswordReset() {
    this.passwordReset = false;
  }

  public resetEmailPassword() {
    this.auth.forgotPassword(this.passResetEmail);
  }

  public onGoBackToSocialLogins() {
    this.useEmailLogin = false;
  }

  private verifyEmailAndPassword(email: string, password: string){
    if( this.regEmail === '' || this.dupEmail === '' || this.regPassword === '' || this.dupPassword === ''){
      this.regError = true;
      this.regErrorReason = 'All fields must be filled out.';
    } else {
      if( this.emailSimpleEval.test(email) ){
        // Check if Password is Syntactically
        if( this.passSimpleEval.test(password) ) {
          // At this point, it's syntactically valid
          this.auth.registerEmail(email, password, this.displayName);
        } else {
          this.regError = true;
          this.regErrorReason = 'Password must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters';
        }
      } else {
        this.regError = true;
        this.regErrorReason = 'There are many unique emails out there, but our system only takes email@example.com. We apologize for any inconvenience.'
      }
    }
  }
}
