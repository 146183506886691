import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-userinvoices',
  templateUrl: './userinvoices.component.html',
  styleUrls: ['./userinvoices.component.css']
})
export class UserinvoicesComponent implements OnInit {

  constructor(private auth: AuthService) {
    // auth.publishAsInvoice();
    // auth.addClientForPublishedInvoices('Charles');
  }

  ngOnInit() {
  }

  /**
   * getInvoices
   */
  public getInvoices() {
    return this.auth.getUserIssuedInvoices();
  }

}
